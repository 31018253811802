import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

import {
	backwardCompApi,
	getAuthorizedChildren,
	isCurrentUserModoOrAdmin,
	repositoryNameSortFunction,
	toast,
} from "../actions/generalUtils"

import { callBackground } from "../popup/tools/extensionUtils"

import { Button } from "../stories/buttons/Button"
import { CheckBox } from "../stories/inputs/checkbox/Checkbox"
import Toggler from "../stories/toggler/Toggler"

import { ReactComponent as Folder } from "../assets/images/folder.svg"
import { ReactComponent as Place } from "../assets/images/place.svg"
import LoaderBars from "../stories/loader/loaderBars/LoaderBars"

export default function Mapping(props) {
	const {
		type,
		actionBtnLabel,
		secondBtnLabel,
		isInExtension,
		close,
		hasSecondBtn,
		label,
		saveCallback,
		product,
		target,
		setTarget,
		isFullWidth,
		hideButtons,
		hasSingleRow,
		origin,
		fetchMappingDataFromExtension,
		isPersoSpaceBlocked,
	} = props

	const dispatch = useDispatch()
	const [t] = useTranslation()
	const hideUnauthorizedRepositoriesLocalStorageValue = localStorage?.getItem(
		"LS_hideUnauthorizedRepositories",
	)

	const actionLoader = useSelector((state) => state.action.loader)
	const mappingLoader = useSelector(
		(state) => state?.[product]?.mappingLoader,
	)
	const mappingData = useSelector((state) => state?.[product]?.mappingData)
	const activeData = useSelector((state) => state?.[product]?.active)

	const activeDataParentIds = Object.values(activeData)?.map((activeItem) => {
		if (product === "pass") {
			return activeItem?.parentId
		} else if (product === "files") {
			return activeItem?.folderId
		}
	})

	const [newTarget, setNewTarget] = useState(target || null)
	const [allRepositoriesAuthorized, setAllRepositoriesAuthorized] =
		useState(true)
	const [hideUnauthorizedRepositories, setHideUnauthorizedRepositories] =
		useState(
			JSON.parse(
				hideUnauthorizedRepositoriesLocalStorageValue || "false",
			) || false,
		)
	const scrollToElementEnabled = useRef(true)

	// OPTIONS SETTINGS FOR PERSONAL SPACES
	const isOptionPersonalSpaceBlocked = useSelector(
		(state) => state.params?.optLockPass?.isPersonalSpaceBlocked,
	)
	const isLockPassPersoSpaceBlocked = !backwardCompApi("1.22.14")
		? false
		: origin === "extension_followIds"
		? isPersoSpaceBlocked
		: isOptionPersonalSpaceBlocked
	const canAccessPersoSpace = !backwardCompApi("1.22.14")
		? true
		: product === "pass" && !isLockPassPersoSpaceBlocked

	/* ----------------------------------- // ----------------------------------- */
	/* -------------------------------- FUNCTIONS ------------------------------- */
	function getDataForTreeStructure() {
		if (!product || fetchMappingDataFromExtension) {
			return
		}

		switch (product?.toLowerCase()) {
			case "pass":
				dispatch({
					type: "LP_GET_MAPPING_CATEGORIES_SAGA",
					payload: {
						isPersoSpaceBlocked: isLockPassPersoSpaceBlocked,
					},
				})

				break

			default:
				return
		}
	}

	function getDataForTreeStructureFromBackground() {
		if (!fetchMappingDataFromExtension || product !== "pass") {
			return
		}

		let loadingState = false

		if (!actionLoader) {
			dispatch({ type: "action/setActionLoader", payload: true })

			loadingState = true
		}

		callBackground(
			{
				message: "LSgetMappingDataForFollowIdsFrame",
				isPersoSpaceBlocked,
			},
			(data) => {
				if (!!data && "error" in data) {
					toast(data?.error, null, "error")

					return
				}

				if (data?.persoCats?.code === 403) {
					dispatch({ type: "params/toggleIsPersonalSpaceBlocked" })
				}

				dispatch({
					type: "pass/setMappingData",
					payload: {
						ids: data?.sharedCats?.ids || [],
						persoData:
							data?.persoCats &&
							!data?.persoCats?.code &&
							!data?.persoCats?.message
								? data?.persoCats?.sort(
										repositoryNameSortFunction,
								  )
								: [],
						sharedData: data?.sharedCats
							? getAuthorizedChildren(
									data?.sharedCats?.tree?.sort(
										repositoryNameSortFunction,
									),
									data?.sharedCats?.ids,
							  )
							: [],
					},
				})

				if (loadingState) {
					dispatch({ type: "action/setActionLoader" })

					loadingState = false
				}
			},
		)
	}

	function getDataForTreeGeneration() {
		return mappingData
	}

	function save() {
		setTarget(newTarget)

		if (typeof saveCallback === "function") {
			saveCallback()
		}

		if (typeof close === "function") {
			close()
		}
	}

	function getActionButtonsProps(newTarget) {
		if (["addPass"].includes(type)) {
			return {
				actionBtn: {
					onClick: () => save(),
					type: "primary",
					label: actionBtnLabel,
					disabled: typeof newTarget?.id === "undefined",
				},
				secondBtn: {
					onClick: () => close(),
					type: "secondary",
					label: secondBtnLabel,
				},
			}
		}
	}

	function isCurrentItemExcluded(itemId) {
		switch (type) {
			case "addCategory":
				return false

			default:
				return activeDataParentIds?.includes(itemId)
		}
	}

	function toggleHideUnauthorizedCats() {
		setHideUnauthorizedRepositories((currentValue) => {
			const newValue = !currentValue

			localStorage?.setItem("LS_hideUnauthorizedRepositories", newValue)

			return newValue
		})
	}

	function triggerSelectedItemObserver(element) {
		if (!element) {
			return
		}

		let observer

		function scrollToElement(parentElement, observer) {
			const selectedItem = parentElement?.querySelector("#selected")

			if (!scrollToElementEnabled?.current) {
				observer?.disconnect()

				return
			}

			if (!!selectedItem) {
				selectedItem?.scrollIntoView({
					behavior: "smooth",
					block: "nearest",
				})

				scrollToElementEnabled.current = false

				if (observer) {
					observer?.disconnect()
				}
			}
		}

		function observerCallback(mutationsList, observer) {
			for (const mutation of mutationsList) {
				if (mutation.type === "childList" && !!mutation?.target) {
					scrollToElement(mutation?.target, observer)
				}
			}
		}

		observer = new MutationObserver(observerCallback)

		observer.observe(element, {
			childList: true,
			subtree: true,
		})

		scrollToElement(element, observer)
	}

	// GENERATE TREES FUNCTIONS
	function generateSubTreeStructure(subtree, isPersoSpace = false) {
		if (!subtree || subtree?.length <= 0) {
			return null
		}

		const getDisabledValueforItem = (item) => {
			if (product === "pass") {
				if (isPersoSpace) {
					return false
				}

				if (
					!isCurrentUserModoOrAdmin() &&
					!mappingData?.ids?.includes(item?.id)
				) {
					return true
				}

				return false
			}
		}

		return subtree?.map((item) => {
			const isItemDisabled = getDisabledValueforItem(item)
			const getItemCheckboxEventValue = () => {
				if (product === "pass") {
					setNewTarget(item)
				}
			}
			const getItemCheckedValue = () => {
				if (product === "pass") {
					return newTarget?.id === item?.id
				}
			}

			if (isItemDisabled && allRepositoriesAuthorized) {
				setAllRepositoriesAuthorized(false)
			}

			return (
				<div
					className="rep"
					key={item.id}
					id={getItemCheckedValue() ? "selected" : ""}
				>
					{(!isItemDisabled ||
						(isItemDisabled && !hideUnauthorizedRepositories)) && (
						<CheckBox
							isInExtension={isInExtension}
							type="radio"
							disabled={isItemDisabled}
							isChecked={getItemCheckedValue()}
							onChange={getItemCheckboxEventValue}
							Icon={<Folder />}
							smallLabel={item?.name}
							isExcludedCatID={isCurrentItemExcluded(item?.id)}
						/>
					)}

					<div className="subReps">
						{generateSubTreeStructure(item?.children, isPersoSpace)}
					</div>
				</div>
			)
		})
	}

	function generateTreeStructure(tree) {
		if (!tree) {
			return null
		}

		const getRootPersoSpaceDisabledValue = () => {
			if (product === "pass") {
				return null
			}
		}
		const getRootSharedSpaceDisabledValue = () => {
			if (product === "pass") {
				return true
			}
		}
		const getRootPersoSpaceValue = () => {
			if (product === "pass") {
				return {
					name: t("mLayout.personnalSpace"),
					id: 0,
					mainId: 0,
				}
			}
		}
		const getRootSharedSpaceValue = () => {
			if (product === "pass") {
				return {
					name: t("mLayout.shared"),
					id: -1,
					mainId: -1,
				}
			}
		}
		const getRootPersoSpaceCheckedValue = (newTargetId) => {
			if (product === "pass") {
				return newTargetId === 0
			}
		}
		const getRootSharedSpaceCheckedValue = (newTargetId) => {
			if (product === "pass") {
				return newTargetId === -1
			}
		}
		const getRootSharedSpaceCheckboxEventValue = () => {
			setNewTarget(getRootSharedSpaceValue())
		}

		return (
			<div className="repsFrame">
				{canAccessPersoSpace && (
					<div className="baseRep">
						<CheckBox
							isInExtension={isInExtension}
							disabled={getRootPersoSpaceDisabledValue()}
							type="radio"
							isChecked={getRootPersoSpaceCheckedValue(
								newTarget?.id,
							)}
							onChange={() =>
								setNewTarget(getRootPersoSpaceValue())
							}
							Icon={<Place />}
							label={t("mLayout.personnalSpace")}
							mappingId={
								getRootPersoSpaceCheckedValue(newTarget?.id)
									? "selected"
									: ""
							}
						/>

						{tree?.persoData?.length > 0 ? (
							<div className="reps">
								{generateSubTreeStructure(
									tree?.persoData,
									true,
								)}
							</div>
						) : null}
					</div>
				)}

				<div className="baseRep">
					<CheckBox
						isInExtension={isInExtension}
						disabled={getRootSharedSpaceDisabledValue()}
						type="radio"
						isChecked={getRootSharedSpaceCheckedValue(
							newTarget?.id,
						)}
						onChange={() => {
							getRootSharedSpaceCheckboxEventValue()
						}}
						Icon={<Place />}
						label={t("mLayout.shared")}
						mappingId={
							getRootSharedSpaceCheckedValue(newTarget?.id)
								? "selected"
								: ""
						}
					/>

					{tree?.sharedData?.length > 0 ? (
						<div className="reps">
							{generateSubTreeStructure(tree?.sharedData)}
						</div>
					) : null}
				</div>
			</div>
		)
	}
	// END GENERATE TREES FUNCTIONS
	/* ------------------------------ END FUNCTIONS ----------------------------- */
	/* ----------------------------------- // ----------------------------------- */

	useEffect(() => {
		if (fetchMappingDataFromExtension && product === "pass") {
			if (actionLoader) {
				return
			}

			if (!mappingData) {
				getDataForTreeStructureFromBackground()
			}
		} else {
			if (mappingLoader) {
				return
			}

			if (!mappingData) {
				getDataForTreeStructure()
			}
		}
	}, [])

	return (
		<div
			className="componentMapping"
			ref={(element) => {
				triggerSelectedItemObserver(element)
			}}
		>
			<div
				className={`mappingWrapper${
					hasSingleRow ? " hasSingleRow" : ""
				}`}
			>
				<div
					className={`mappingContainer
						${isFullWidth ? " isFullWidth" : ""}
						${
							hasSingleRow &&
							(!isInExtension ||
								(isInExtension &&
									origin === "extension_followIds"))
								? " hasSingleRow"
								: ""
						}
					`}
				>
					{["addPass"].includes(type) && (
						<h5 className="mappingTitle">{label}</h5>
					)}

					{((isInExtension && !actionLoader) ||
						(!isInExtension && !mappingLoader)) && (
						<div className="mappingContent">
							{generateTreeStructure(getDataForTreeGeneration())}

							{!allRepositoriesAuthorized && (
								<div className="line">
									<Toggler
										value={hideUnauthorizedRepositories}
										onSelect={toggleHideUnauthorizedCats}
										text={t(
											"pass.aPass.hideUnauthorizedCats",
										)}
									/>
								</div>
							)}
						</div>
					)}

					{mappingLoader && (
						<div className="mappingLoader">
							<LoaderBars />
						</div>
					)}
				</div>

				{!hideButtons && (
					<div className="buttonsList">
						<Button
							{...getActionButtonsProps(newTarget)?.actionBtn}
						/>

						{hasSecondBtn && (
							<Button
								{...getActionButtonsProps(newTarget)?.secondBtn}
							/>
						)}
					</div>
				)}
			</div>
		</div>
	)
}

Mapping.defaultProps = {
	hasSecondBtn: false,
	isInExtension: false,
	saveCallback: null,
	target: null,
	setTarget: null,
	data: {},
	isFullWidth: false,
	hideButtons: false,
	hasSingleRow: false,
	treeData: {},
	origin: "",
	fetchMappingDataFromExtension: false,
	isPersoSpaceBlocked: false,
}

Mapping.propTypes = {
	type: PropTypes.string,
	data: PropTypes.object,
	actionBtnLabel: PropTypes.string,
	secondBtnLabel: PropTypes.string,
	close: PropTypes.func,
	hasSecondBtn: PropTypes.bool,
	labelHelper: PropTypes.string,
	isInExtension: PropTypes.bool,
	label: PropTypes.string,
	saveCallback: PropTypes.func,
	target: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	setTarget: PropTypes.func,
	product: PropTypes.string,
	isFullWidth: PropTypes.bool,
	hideButtons: PropTypes.bool,
	hasSingleRow: PropTypes.bool,
	treeData: PropTypes.object,
	origin: PropTypes.string,
	fetchMappingDataFromExtension: PropTypes.bool,
	isPersoSpaceBlocked: PropTypes.bool,
}
