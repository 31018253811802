import React, { useEffect, useState, useCallback } from "react"
import PropTypes from "prop-types"

import { useTranslation } from "react-i18next"

//import AnimFrame from "../components/animations/AnimFrame"
import LoaderBars from "../components/animations/LoaderBars"

import {
	callBackground,
	useMutationObservable,
	renamePerso,
} from "./tools/extensionUtils"

import { ReactComponent as Place } from "../assets/images/place.svg"
import { ReactComponent as Key } from "../assets/images/key.svg"
import { ReactComponent as Logo } from "../assets/logo2.svg"
import { ReactComponent as Search } from "../assets/images/search.svg"

const ShowIdsMemo = React.memo(function ShowIdsBloc({
	targetedInput,
	showIds,
	toggleShowIds,
	inputsToComplete,
	cbLoading,
}) {
	const [t] = useTranslation()

	const [ids, setIds] = useState([])
	const [idsLoading, setIdsLoading] = useState(false)
	const [_showIds, _setShowIds] = useState(false)
	const [searchString, setSearchString] = useState("")
	const [myInput, setMyInput] = useState(inputsToComplete[1])
	const [isRevealed, setIsRevealed] = useState(false)
	const [isAllowed, setIsAllowed] = useState(1)
	const [secretPassword, setSecretPassword] = useState("")
	const PASSWORD_VISIBLE = "notallow"

	const onInputMutation = useCallback(
		(mutationInput) => {
			// console.log(mutationInput[0]?.target?.type, "mutationInput")
			// Is call when the input type of the password change
			if (mutationInput[0].target.type === "password") {
				setIsRevealed(false)
			} else {
				// console.log("here")
				setIsRevealed(true)
			}
		},
		[setIsRevealed],
	)

	// console.log(myInput, "myInput")

	useMutationObservable(myInput, onInputMutation)

	useEffect(() => {
		watchForBgRequests()
	}, [])

	useEffect(() => {
		cbLoading(idsLoading)
	}, [idsLoading])

	useEffect(() => {
		// Toggle between real and fake password according to the input type
		if (isAllowed !== -1) return

		if (isRevealed) {
			valInjection(myInput, PASSWORD_VISIBLE)
		} else {
			valInjection(myInput, secretPassword)
		}
	}, [isRevealed, isAllowed])

	useEffect(() => {
		// Store the password input in state
		if (
			inputsToComplete.length === 1 &&
			inputsToComplete[0].type === "password"
		) {
			setMyInput(inputsToComplete[0])
		} else if (inputsToComplete[1]) {
			setMyInput(inputsToComplete[1])
		}
	}, [inputsToComplete])

	useEffect(() => {
		if (showIds && !_showIds) {
			_setShowIds(true)
		} else if (!showIds && _showIds) {
			setTimeout(() => _setShowIds(false), 200)
		}
	}, [showIds])

	useEffect(() => {
		if (targetedInput) loadIds()
	}, [targetedInput])

	useEffect(() => {
		if (!secretPassword) return

		valInjection(
			myInput,
			isAllowed < 0 && isRevealed ? PASSWORD_VISIBLE : secretPassword,
		)
	}, [secretPassword])

	function watchForBgRequests() {
		/* eslint-disable no-undef */
		try {
			if (chrome.runtime) {
				chrome.runtime.onMessage.addListener((request, sender) => {
					// console.log("In ShowIds onMessage listener : ", request, sender, sendResponse)

					if (sender.id !== chrome.runtime.id) {
						return console.warn(
							"[LS] Unauthorized sender trying to access your page data. Details of sender : ",
							sender,
						)
					}

					// Log from BG utility
					if (request.message === "LSautoFill") {
						setSecretPassword(request.values)
					}
				})
			}
		} catch (e) {}
	}

	function _toggleShowIds() {
		//console.log("88888888888 - local show ids")
		_setShowIds(false)

		// setTimeout is for css animation delay
		return setTimeout(() => {
			toggleShowIds()
		}, 150)
	}

	// Autocomplete base function
	function autoComplete(item) {
		if (
			inputsToComplete.length === 1 &&
			inputsToComplete[0].type === "password"
		) {
			setIsAllowed(item.view)
			setMyInput(inputsToComplete[0])
			setSecretPassword(item.password)
			valInjection(
				inputsToComplete[0],
				item?.view < 0 && isRevealed ? PASSWORD_VISIBLE : item.password,
			)
		} else {
			if (
				inputsToComplete[0] &&
				item.username &&
				inputsToComplete[0].value !== item.username
			)
				valInjection(inputsToComplete[0], item.username)

			if (
				inputsToComplete[1] &&
				item.password &&
				inputsToComplete[1].value !== item.password
			) {
				if (
					(inputsToComplete[1]?.getAttribute("type") === "text" &&
						!isRevealed) ||
					(!inputsToComplete[1]?.getAttribute("type") && !isRevealed)
				) {
					setIsRevealed(true)
				}

				setIsAllowed(item.view)
				setMyInput(inputsToComplete[1])
				setSecretPassword(item.password)
				valInjection(
					inputsToComplete[1],
					item?.view < 0 && isRevealed
						? PASSWORD_VISIBLE
						: item.password,
				)
			}
		}

		_toggleShowIds()

		return callBackground({ message: "LSautocompleteDone" })
	}

	/*
		Save fix-trying for https://www2.carpimko.com/Comptes/Connexion?ReturnUrl=%2F

		function checkIfStringHasOnlyDigits(string) {
			if (string.match(/^[0-9]+$/) != null) return true
				else return false
		}

		if (checkIfStringHasOnlyDigits(val)) _valToInject = parseInt(val)

		if (force) {
			console.log("------------------")
			console.log("----117---->",el.value)
			//console.log(val.split())

			// If fields is dynamically fullfield with "_" chars by site
			if (el.value.split('_').length === el.value.length + 1) {
				let ogSpaceLength = el.value.length - 1
				let totalStr = ""

				//console.log(ogSpaceLength, "ogSpaceLength")

				el.value = '_'.repeat(el.value.length)

				val.split("").forEach((letter,i) => {

					setTimeout(() => {
						let spacer = '_'.repeat(ogSpaceLength - i)
						totalStr += letter

						console.log(i, "->", totalStr + spacer)

						let	ev1 = el.ownerDocument.createEvent('HTMLEvents'),
							ev2 = el.ownerDocument.createEvent('HTMLEvents')

						dispatchEvents()
						ev1.initEvent('change', true, true)
						ev2.initEvent('input', true, true)
						el.dispatchEvent(ev1)
						el.dispatchEvent(ev2)


						el.value = totalStr + spacer
						el.setSelectionRange(i+1, i+1)


						//if (i === ogSpaceLength) return setTimeout(() => el.blur(), 100)
						//	else return
					}, 100*i)
		}


		// Hover before inject
		var hoverEvent = new MouseEvent('mouseover', {
			'view': window,
			'bubbles': true,
			'cancelable': true
		})
		el.dispatchEvent(hoverEvent)
		//el.dispatchEvent(spawnEvent(el, 'hover'))
	}*/

	// set value of the given element
	function valInjection(el, val) {
		function dispatchEvents() {
			el.dispatchEvent(spawnEvent(el, "input"))
			el.dispatchEvent(spawnEvent(el, "change"))
			el.dispatchEvent(spawnEvent(el, "keydown"))
			el.dispatchEvent(spawnEvent(el, "keypress"))
			el.dispatchEvent(spawnEvent(el, "keyup"))
		}

		// First setter of value : simplest way (not working on some sites)
		el.value = val

		el.dispatchEvent(new Event("input"))
		el.dispatchEvent(new Event("change"))
		el.dispatchEvent(new Event("keypress"))
		el.dispatchEvent(new Event("keydown"))
		el.dispatchEvent(new Event("keyup"))

		let ev1 = el.ownerDocument.createEvent("HTMLEvents"),
			ev2 = el.ownerDocument.createEvent("HTMLEvents")

		// First way of injecting
		el.click()
		el.focus()
		dispatchEvents()
		if (el.value !== val) {
			el.value = val

			el.dispatchEvent(new Event("input"))
			el.dispatchEvent(new Event("change"))
			el.dispatchEvent(new Event("keypress"))
			el.dispatchEvent(new Event("keydown"))
			el.dispatchEvent(new Event("keyup"))
		}

		// Second way of injecting
		// Inject -> then -> normalize state for site as humanized event
		dispatchEvents()
		ev1.initEvent("change", true, true)
		ev2.initEvent("input", true, true)
		ev2.initEvent("keydown", true, true)
		ev2.initEvent("keypress", true, true)
		ev2.initEvent("keyup", true, true)

		el.dispatchEvent(ev1)
		el.dispatchEvent(ev2)

		if (el.value !== val) {
			el.value = val

			el.dispatchEvent(new Event("input"))
			el.dispatchEvent(new Event("change"))
			el.dispatchEvent(new Event("keypress"))
			el.dispatchEvent(new Event("keydown"))
			el.dispatchEvent(new Event("keyup"))

			return
		} else return
	}

	// Util to create proper navigator events for autocompletion
	function spawnEvent(el, type) {
		let ev
		if (window?.KeyboardEvent) {
			ev = new window.KeyboardEvent(type, {
				bubbles: true,
				cancelable: false,
			})
		} else {
			ev = el.ownerDocument.createEvent("Events")
			ev.initEvent(type, true, false)
			ev.charCode = ""
			ev.keyCode = ""
			ev.which = ""
			ev.srcElement = el
			ev.target = el
		}
		return ev
	}

	// Get ids with bg service
	function loadIds() {
		function cb(ids) {
			setIdsLoading(false)

			if (!ids || ids?.error || ids === "LSerrorOccured") {
				return _toggleShowIds()
			} else {
				return setIds(ids)
			}
		}
		setIdsLoading(true)
		return callBackground({ message: "LSgetIds" }, cb)
	}

	function setSearchVal(e) {
		e.preventDefault()
		e.stopPropagation()
		return setSearchString(e.target.value)
	}

	function handlePwdClick(credential) {
		// dispatch({ type: "POPUP_SEND_PASS_USAGE_LOG", payload: { credentialId: credential.id } })
		autoComplete(credential)
		return callBackground(
			{ message: "LSpostPassLogs", credentialId: credential.id },
			() => {},
		)
	}

	// Generate the DOM tree to create the credentials list, based on the object stored in redux retrieved from API
	const generateCategories = () => {
		if (idsLoading) {
			return <LoaderBars mini />
		} else if (!ids?.length) {
			return (
				<span className="noResult">
					<Key /> {t("popup.list.noResult")}
				</span>
			)
		} else {
			let organizedPasswords = {}

			for (let i = 0; i < ids.length; i++) {
				const currentCredential = ids[i]
				const currentCategory = currentCredential.categoryName
				// If the current credential's category isn't already in the new list, add it.
				if (!organizedPasswords[`category__${currentCategory}`])
					organizedPasswords[`category__${currentCategory}`] = []
				organizedPasswords[`category__${currentCategory}`].push(
					currentCredential,
				)
			}

			let categories = Object.keys(organizedPasswords)

			return categories.map((category, indexA) => {
				let displayAPassword = false

				const credentialsToDisplay = organizedPasswords[category].map(
					(credential, indexB) => {
						if (
							credential?.name
								?.toUpperCase()
								.includes(searchString?.toUpperCase()) ||
							credential?.username
								?.toUpperCase()
								.includes(searchString?.toUpperCase()) ||
							credential?.categoryName
								?.toUpperCase()
								.includes(searchString?.toUpperCase())
						) {
							// If a password matches the search (or the search isn't enable), set the displayAPassword to true.
							displayAPassword = true

							return (
								<div
									className="category-content"
									key={indexB}
									onClick={() => handlePwdClick(credential)}
								>
									<b>{credential.name}</b>
									<div className="category-content-text">
										<span>
											{credential.username ? (
												credential.username
											) : (
												<i>
													{t(
														"popup.list.noCredential",
													)}
												</i>
											)}
										</span>
										{/*<small><Place/> {credential.categoryName}</small>*/}
									</div>
								</div>
							)
						} else return null
					},
				)

				const currentCategoryName = renamePerso(
					category.replace("category__", ""),
					t,
				)

				if (displayAPassword) {
					return (
						<div className="category" key={indexA}>
							<h3>
								<Place />
								<span
									className="categoryCatName"
									title={currentCategoryName}
								>
									{currentCategoryName}
								</span>
							</h3>
							{credentialsToDisplay}
						</div>
					)
				} else return null
			})
		}
	}

	if (!showIds) return null

	return (
		<div
			className={`showIdsFrame ${_showIds ? "show" : ""}`}
			style={{ visibility: "hidden", width: "100%" }}
		>
			<div className="showIdsFrame-container">
				<div className="head">
					<Logo />
					{/*<div className="close" onClick={_toggleShowIds}><Close/></div>*/}
				</div>

				{ids.length > 5 ? (
					<div className="searchContainer">
						<Search />
						<input
							autoFocus
							type="text"
							placeholder={t("popup.list.search")}
							value={searchString}
							onChange={(e) => setSearchVal(e)}
						/>
					</div>
				) : null}

				<div className="list">{generateCategories()}</div>
			</div>
		</div>
	)
})

export default ShowIdsMemo

ShowIdsMemo.propTypes = {
	inputsToComplete: PropTypes.arrayOf(PropTypes.node),
	toggleShowIds: PropTypes.func,
	targetedInput: PropTypes.node,
	showIds: PropTypes.bool,
	cbLoading: PropTypes.func,
}
