import { createSlice } from "@reduxjs/toolkit"

const base = {
	callDate: "",
	organisationData: null,
	distributionRolesData: null,
	managersData: null,
	dashboardManagementLicence: null,
	dashboardGroupsGeneral: null,
	dashboardGroups: null,
}

const dashboardMNGSlice = createSlice({
	name: "dashboardMNG",
	initialState: {
		productLoading: true,
		loading: true,
		data: null,
		openList: null,
		infoSelect: [],
		valueSelect: null,
		showList: [],
		fullList: [],
		layoutNavIndex: 0,
		boardNavIndex: 0,
		selectIndex: null,
		selectDataComponentUse: [],
		oldDataComponantUse: [],
		selectMetricComponent: null,
		selectSagaList: [],
		paginLoading: false,
		storageListInfo: null,
		domainActive: null,
		pagingLimit: true,
		actionLoading: false,
		openPopup: false,
		managersList: null,
		statsManagersList: null,
		subOrgaList: null,
		popupType: "new",
	},
	reducers: {
		init: (state) => {
			state.data = base
			state.loading = false
			state.productLoading = true
			state.openList = null
			state.infoSelect = []
			state.layoutNavIndex = 0
			state.boardNavIndex = 0
			state.showList = []
			state.fullList = []
			state.selectDataComponentUse = []
			state.oldDataComponantUse = []
			state.selectMetricComponent = null
			state.selectSagaList = []
			state.paginLoading = false
			state.valueSelect = null
			state.selectIndex = null
			state.storageListInfo = null
			state.domainActive = null
			state.pagingLimit = true
			state.openPopup = false
			state.managersList = null
			state.statsManagersList = null
			state.subOrgaList = null
			state.popupType = "new"
		},
		setLoader: (state, action) => {
			const rightPayload = [
				"callDate",
				"organisationData",
				"dashboardManagementLicence",
				"dashboardGroupsGeneral",
				"dashboardGroups",
				"distributionRolesData",
				"managersData",
			]
			let payloadIsOk = false

			if (action.payload?.isLocal) {
				if (action.payload?.dataLocalStorage) {
					Object.keys(action.payload?.dataLocalStorage).forEach(
						(item) => {
							const itemIsInRightPayload = rightPayload.find(
								(searchedItem) => searchedItem === item,
							)

							if (itemIsInRightPayload) payloadIsOk = true
						},
					)
				}

				if (!action.payload) state.data = null
				else if (payloadIsOk) {
					state.data = {
						...state.data,
						...action.payload?.dataLocalStorage,
					}

					const dataForLS = { ...state.data }

					if ("dashboardManagementLicence" in dataForLS) {
						delete dataForLS.dashboardManagementLicence
					}

					localStorage.setItem(
						"dashboardMNG",
						JSON.stringify(dataForLS),
					)
				}
			} else {
				if (action.payload) {
					if (rightPayload.includes(action.payload.key))
						payloadIsOk = true
				}

				if (!action.payload) state.data = null
				else if (payloadIsOk) {
					state.data[action.payload?.key] = action.payload?.value

					if (
						!["dashboardManagementLicence"].includes(
							action.payload.key,
						)
					) {
						state.data.callDate = action.payload?.callDate

						const dataForLS = { ...state.data }

						if ("dashboardManagementLicence" in dataForLS) {
							delete dataForLS.dashboardManagementLicence
						}

						localStorage.setItem(
							"dashboardMNG",
							JSON.stringify(dataForLS),
						)
					}
				}
			}
		},
		setProductLoading: (state, action) => {
			if (action.payload) state.productLoading = action.payload
			else state.productLoading = false
		},
		toggleLoading: (state, action) => {
			if (!action.payload) state.loading = false
			else state.loading = action.payload
		},
		paginLoading: (state, action) => {
			if (!action.payload) state.paginLoading = false
			else state.paginLoading = action.payload
		},
		setActionLoading: (state, action) => {
			if (!action.payload) state.actionLoading = null
			else state.actionLoading = action.payload
		},
		setLAR: (state, action) => {
			if (!action.payload) state.lastActionResult = null
			else state.lastActionResult = action.payload
		},
		setOpenList: (state, action) => {
			if (!action.payload) state.openList = null
			else state.openList = action.payload
		},
		setInfoSelect: (state, action) => {
			if (!action.payload) state.infoSelect = []
			else state.infoSelect = action.payload
		},
		setValueSelect: (state, action) => {
			if (!action.payload) state.valueSelect = null
			else state.valueSelect = action.payload

			state.pagingLimit = true
		},
		setLayoutNavIndex: (state, action) => {
			if (!action.payload) state.layoutNavIndex = 0
			else state.layoutNavIndex = action.payload
		},
		setBoardNavIndex: (state, action) => {
			if (!action.payload) state.boardNavIndex = 0
			else state.boardNavIndex = action.payload
		},
		setShowList: (state, action) => {
			if (!action.payload) state.showList = []
			else state.showList = action.payload
		},
		setFullList: (state, action) => {
			if (!action.payload) state.fullList = []
			else state.fullList = action.payload
		},
		setSelectDataComponentUse: (state, action) => {
			if (!action.payload) state.selectDataComponentUse = []
			else state.selectDataComponentUse = action.payload
		},
		setOldDataComponantUse: (state, action) => {
			state.oldDataComponantUse = action.payload
		},
		setSelectMetricComponent: (state, action) => {
			if (!action.payload && action.payload !== 0) {
				state.selectMetricComponent = null
			} else state.selectMetricComponent = action.payload
		},
		setSelectSagaList: (state, action) => {
			if (!action.payload) state.selectSagaList = []
			else state.selectSagaList = action.payload
		},
		setSelectIndex: (state, action) => {
			if (!action.payload && action.payload !== 0)
				state.selectIndex = null
			else state.selectIndex = action.payload
		},
		setStorageListInfo: (state, action) => {
			if (!action.payload) state.storageListInfo = null
			else state.storageListInfo = action.payload[0]
		},
		setDomainActive: (state, action) => {
			if (!action.payload) state.domainActive = null
			else state.domainActive = action.payload
		},
		setPagingLimit: (state, action) => {
			state.pagingLimit = action.payload
		},
		setOpenPopup: (state, action) => {
			state.openPopup = action.payload
		},
		setManagersList: (state, action) => {
			if (!action.payload) state.managersList = null
			else state.managersList = action.payload
		},
		setStatsManagersList: (state, action) => {
			if (!action.payload) state.statsManagersList = null
			else state.statsManagersList = action.payload
		},
		setSubOrgaList: (state, action) => {
			if (!action.payload) state.subOrgaList = null
			else state.subOrgaList = action.payload
		},
		setPopupType: (state, action) => {
			state.popupType = action.payload
		},
	},
})

export const { init } = dashboardMNGSlice.actions

export default dashboardMNGSlice.reducer
